import {getServiceOrderConfigInfoReq} from "@/common/http/core/core";
import {getWebPermissionReq} from "@/common/http/user/user_api";

let serviceOrderConfig = null;

let userWebPermission = null;

// 获取服务订单配置信息
async function getServiceOrderConfig() {
    if (serviceOrderConfig == null) {
        let res = await getServiceOrderConfigInfoReq()
        if (res.success) {
            serviceOrderConfig = res.data
        }
    }
    return serviceOrderConfig
}

async function getCacheUserWebPermission() {
    if (userWebPermission == null) {
        let res = await getWebPermissionReq()
        if (res.success) {
            userWebPermission = res.data
        }
    }
    return userWebPermission
}

function resetCache() {
    serviceOrderConfig = null;
    userWebPermission = null;
}

export {
    resetCache,
    getServiceOrderConfig,
    getCacheUserWebPermission,
}
