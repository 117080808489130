<template>
  <router-view>
  </router-view>
</template>

<script>

import {onMounted} from "vue";

export default {
  name: 'BankWeb',
  setup() {
    onMounted(() => {
      console.log('App mounted');

      document.body.style.setProperty('--el-color-primary', '#257590');
      document.body.style.setProperty('--el-color-primary-light-9', '#409EFF');
      document.body.style.setProperty('--el-color-primary-light-3', '#409EFF');
    });
    return {
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  margin: 0;
  padding: 0;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
