<template>
  <div id="login">
    <div id="loginContent">
      <div class="titleDiv">
        联合营销服务系统
      </div>
      <el-form id="loginForm" :model="loginData" :rules="loginRules" ref="loginForm" label-width="80px"
               @keyup.enter="goLogin()">

        <el-input
            v-model="loginData.user_name"
            size="large"
            placeholder="请输入用户名"
        >
          <template #prefix>
            <div class="prefixIconDiv">
              <img class="prefixImg" src="@/assets/img/login/user_name.png" alt="">
            </div>
          </template>
        </el-input>

        <el-input
            v-model="loginData.password"
            size="large"
            type="password"
            placeholder="请输入密码"
        >
          <template #prefix>
            <div class="prefixIconDiv">
              <img class="prefixImg" src="@/assets/img/login/password.png" alt="">
            </div>
          </template>
        </el-input>

        <el-input
            v-model="loginData.auth_code"
            size="large"
            placeholder="请输入验证码"
        >
          <template #prefix>
            <div class="prefixIconDiv">
              <img class="prefixImg" src="@/assets/img/login/auth_code.png" alt="">
            </div>
          </template>

          <template #suffix>
            <div class="suffixIconDiv">
              <img class="authImg" :src="pageData.authImg" alt="验证码" @click="getAuthImg">
              <img class="suffixImg" src="@/assets/img/login/refresh.png" alt="" @click="getAuthImg">
            </div>
          </template>
        </el-input>

        <el-button type="primary" color="#2EAEB7" class="loginBtn"
                   @click="goLogin()">
          <div style="color: white; font-size: 16px;">登&emsp;录</div>
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {login} from "@/common/http/user/user_api";
import * as utils from '@/common/utils';
import {updateTokenInfo} from '@/common/utils';
import {baseUrl} from "@/common/define/define";

export default defineComponent({
  name: "LoginView",
  setup() {
    const loginForm = ref()
    const loginData = reactive({
      user_name: '',
      password: '',
      auth_code: '',
      req_time: "",
    })
    const loginRules = reactive({
      user_name: [       //改了这里
        {required: true, message: '请输入用户名', trigger: 'blur'},
        {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
      ],
      password: [       //改了这里
        {required: true, message: '请输入密码', trigger: 'blur'},
        {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
      ],
      auth_code: [
        {required: true, message: '请输入验证码', trigger: 'blur'},
      ]
    })

    const pageData = reactive({
      authImg: ''
    })

    onMounted(() => {
      getAuthImg()
    })

    const getAuthImg = () => {
      loginData.req_time = getUuid()
      pageData.authImg = baseUrl + 'auth/image?time=' + loginData.req_time
    }

    function getUuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }

    const goLogin = () => {
      loginForm.value.validate((valid) => {
        if (!valid) {
          return false;
        }

        const loading = utils.getLoading();

        login(loginData).then((res) => {
          loading.close()
          if (res.success) {
            updateTokenInfo(res.data)
            window.location.replace("/admin");
          } else {
            ElMessage.error(res.msg);
            getAuthImg()
          }
        });
        return true;

      });
    }

    return {
      loginData,
      loginRules,
      loginForm,
      goLogin,
      pageData,
      getAuthImg,
    };
  }
})
</script>

<style lang="less" scoped>
#login {
  padding: 0;
  background-image: url("@/assets/img/bg/login.png");
  background-size: 100% 100%;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

#loginForm {
  margin-top: 32px;
  margin-left: 36px;
  margin-right: 36px;
  width: 428px;
}

#loginContent {
  background-color: #2F5E6E;
  text-align: center;
  width: 500px;
  height: 400px;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(70% - 200px);
  border-radius: 10px;
  box-shadow: 2px 2px;

  .el-input {
    margin-bottom: 12px;
  }
}

.titleDiv {
  width: 100%;
  margin-top: 40px;
  color: white;
  font-size: 20px;
  line-height: 28px;
}

.prefixIconDiv {
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.suffixIconDiv {
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .suffixImg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }

  .authImg {
    width: 100px;
    height: 40px;
  }
}

.loginBtn {
  height: 44px;
  margin-top: 32px;
  width: 428px;
  font-size: 17px;
}

</style>
