import {baseUrl} from "@/common/define/define";
import {ElLoading} from "element-plus";
import {resetCache} from "@/common/manager/cache_manager";

const kTokenKey = "accessToken"

// 判断用户是否登录
function isLocalLogin() {
    const token = getUserToken();
    return !(token === undefined || token === null || token === '');
}

function getUserToken() {
    return localStorage.getItem(kTokenKey);
}

function updateTokenInfo(data) {
    localStorage.setItem(kTokenKey, data);
}

function logout() {
    updateTokenInfo('');
    sessionStorage.setItem('selectedRouter', "")
    sessionStorage.setItem('tabRouters', "")

    resetCache()
}

function getFileUploadUrl() {
    return baseUrl + "core/v1/file/upload"
}

function getLoading() {
    return ElLoading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.4)',
    })
}

export {
    isLocalLogin,
    getUserToken,
    updateTokenInfo,
    getFileUploadUrl,
    getLoading,
    logout
}

