import {createRouter, createWebHistory} from 'vue-router'
import IndexView from '../views/IndexView.vue'
import LoginPage from '../views/Login.vue';

import {isLocalLogin} from "@/common/utils";
import {getRouterList} from "@/router/dynamic";

const routes = [
    {
        path: '/',
        name: 'index',
        component: IndexView
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/admin',
        name: 'AdminView',
        component: () => import('../views/AdminView.vue'),
        meta: {
            requiredAuth: true
        },
        children: [

        ]
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

let loadRouter = 0

// 登录拦截
router.beforeEach((to, from, next) => {
    try {
        if (loadRouter === 0) {
            getRouterList()

            // let isBreak = false
            // for (let i = 0; i < routes.length; i++) {
            //     if (routes[i].path === to.path) {
            //         isBreak = true
            //         break
            //     }
            // }
            //
            // if (!isBreak) {
            //     next({path: '/login'});
            //     return
            // }

            loadRouter = 1
            if (to.matched.length === 0 || to.meta.requiredAuth) {
                next({path: to.path});
            } else {
                next();
            }
        }

        if (to.meta.requiredAuth) {
            if (!isLocalLogin()) {
                next({
                    path: '/login'
                })
            } else {
                next()
            }
        } else {
            next()
        }
    } catch (error) {
        console.log("router error", error)
        next({
            path: '/login'
        })
    }

})

export default router
