<template>
  <div class="home">
    <h1>INDEX PAGE</h1>
  </div>
</template>


<script>

export default {
  name: 'IndexView'
}
</script>