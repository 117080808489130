import {deleteReq, getReq, postReq, putReq} from "../request";

function login(params) {
    return postReq('user/v1/loginForWeb', params);
}

function getBaseInfo() {
    return getReq('user/v1/info')
}

function getUserIsAdminReq() {
    return getReq('user/v1/isAdmin')
}

function addManagerReq(params) {
    return postReq('user/v1/manager', params)
}

function updateManagerReq(params) {
    return putReq('user/v1/manager', params)
}

function getManagerListReq(params) {
    return getReq('user/v1/manager/list', params)
}

function deleteManagerReq(id) {
    return deleteReq('user/v1/manager/' + id)
}

function isAdministratorReq() {
    return getReq('user/v1/isAdmin')
}

function searchDispatchUserReq(params) {
    return getReq('user/v1/searchDispatch', params)
}

function getWebPermissionReq() {
    return getReq('user/v1/web/permission')
}

export {
    login,
    getBaseInfo,
    getUserIsAdminReq,
    addManagerReq,
    getManagerListReq,
    deleteManagerReq,
    updateManagerReq,
    isAdministratorReq,
    searchDispatchUserReq,
    getWebPermissionReq,
}
