import {getFileReq, getReq} from "../request";

const url = "/core/v1";

function getConfigByNameReq(name) {
    return getReq(url + "/config/byName/" + name);
}

function getProvinceListReq() {
    return getReq(url + "/area/sub/" + 0);
}

// 获取服务订单配置信息
function getServiceOrderConfigInfoReq() {
    return getReq(url + "/config/serviceOrder")
}

// 获取服务商类型列表
function getServiceMerchantTypeListReq() {
    return getReq(url + "/config/serviceMerchantType")
}

function downloadImportTemplateReq(type) {
    return getFileReq(url + "/file/download/merchantTemplate?file_type=" + type);
}

export {
    getConfigByNameReq,
    getProvinceListReq,
    getServiceOrderConfigInfoReq,
    getServiceMerchantTypeListReq,
    downloadImportTemplateReq,
};
