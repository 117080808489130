import router from "@/router/index";
import {emitter, reloadSideRouterKey} from "@/common/event/EventBus";

let routerList = [];

function dynamicAddRouter(data) {
    if (data === null || data === undefined) {
        return;
    }
    if (!(data instanceof Array)) {
        return;
    }
    data.forEach(item => {
        if (item.children) {
            item.children.forEach(child => {
                router.addRoute("AdminView", {
                    path: child.path,
                    name: child.name_en,
                    component: () => import(`@/views/${child.component}.vue`),
                    meta: {
                        requiredAuth: true,
                    }
                })
            })
        }
    })

    routerList = data;
    sessionStorage.setItem("cacheRouterList", JSON.stringify(data));
    emitter.emit(reloadSideRouterKey, "");
}

function getRouterList() {
    if (routerList.length < 1) {
        let cacheRouterList = sessionStorage.getItem("cacheRouterList");
        if (cacheRouterList) {
            routerList = JSON.parse(cacheRouterList);
            if (routerList.length > 0) {
                dynamicAddRouter(routerList);
            }
        }
    }
    return routerList
}

export {dynamicAddRouter, getRouterList}
