import axios from "axios";
import {baseUrl, NEED_LOGIN, SUCCESS_CODE} from "../define/define";
import md5 from 'js-md5';


//  让请求在浏览器中允许跨域携带cookie
axios.defaults.withCredentials = false;

import { getUserToken, isLocalLogin, updateTokenInfo } from "../utils";

// 使用自定义配置新建一个 axios 实例
const service = axios.create({
    // 基础的请求地址
    baseURL: baseUrl,
    // 设置超时时间 5s
    timeout: 5000
});

const fileService = axios.create({
    // 基础的请求地址
    baseURL: baseUrl,
    // 设置超时时间 5s
    timeout: 5000,
})

const aliOssService = axios.create({
    // 基础的请求地址
    baseURL: baseUrl,
    // 设置超时时间 5s
    timeout: 5000,
})

function setRequestHeader(config) {
    // 在发送请求之前做些什么
    config.headers.Authorization = getUserToken();
    config.headers['G-App-Id'] = "fe47e51377a34e53b22ba7efe33779f4";
    config.headers['G-Platform'] = "web";
    config.headers['G-Version'] = "1.0.0";
    config.headers['G-Device'] = "web";
    config.headers['G-Mac'] = "...:12345";

    let timestamp = new Date().getTime()/1000
    config.headers['G-Timestamp'] = timestamp
    config.headers['G-Sign'] = getSign(timestamp)
}

fileService.interceptors.request.use(function (config) {
    setRequestHeader(config)
    config.responseType = 'blob'
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
   setRequestHeader(config)

    let url = config.url;
    if (url.includes('login') || !isLocalLogin) {
        return config
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

aliOssService.interceptors.request.use(function (config) {
    config.responseType = 'blob'
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
})

fileService.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const blob = new Blob([response.data]);//处理文档流
    const fileName = decodeURI(response.headers['filename']);
    const eLink = document.createElement('a');
    eLink.download = fileName;
    eLink.style.display = 'none';
    eLink.href = URL.createObjectURL(blob);
    document.body.appendChild(eLink);
    eLink.click();
    URL.revokeObjectURL(eLink.href); // 释放URL 对象
    document.body.removeChild(eLink);
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

aliOssService.interceptors.response.use(function (response) {
    return response.data
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let respData = response.data;
    if (respData.code === NEED_LOGIN) {
        setTimeout(function () {
            top.location.href = '/login'
        }, 1000)
    }

    if (respData.token) {
        updateTokenInfo(respData.token);
    }

    return {
        success: respData.code === SUCCESS_CODE,
        data: respData.data,
        msg: respData.msg
    };
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

function getSign(timestamp) {
    return md5(timestamp + '-gtrive-' + timestamp);
}

function getReq(url, params) {
    return service({
        method: 'get',
        url: url,
        params: params
    });
}

function postReq(url, params) {
    return service({
        method: 'post',
        url: url,
        data: params
    });
}

function putReq(url, params) {
    return service({
        method: 'put',
        url: url,
        data: params
    });
}

function deleteReq(url, params) {
    return service({
        method: 'delete',
        url: url,
        params: params
    });
}

function getFileReq(url, params) {
    return fileService({
        method: 'get',
        url: url,
        params: params
    });
}

function getAliOssFileReq(url, params) {
    return aliOssService({
        method: 'get',
        url: '/core/v1/file/download/aliOSS',
        params: params
    });
}

// export default service;
export { getReq, postReq, putReq, deleteReq, getFileReq, getAliOssFileReq }
