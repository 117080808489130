// 服务器地址
// export let baseUrl = "http://127.0.0.1:10100/"
export let baseUrl = "https://work.fengminghui.com/api/"
// export let baseUrl = "https://testwork.fengminghui.com/api/"

export let SUCCESS_CODE = 100000;
export let NEED_LOGIN = 100099;

export let defaultPage = {
    index: 1,
    size: 20,
}
